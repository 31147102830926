import React from "react";
import { FaTelegram, FaTwitter } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import IconBtn from "./IconBtn";

export default function WaitlistHeader() {
   const navi = useNavigate();
   return (
      <div className="container">
         <div className="header py-6 flex justify-between items-center text-white">
            <div className="logo-con">
               <img
                  src="/assets/logo.png"
                  alt="Alchemist DAO"
                  className="w-[200px]"
               />
            </div>
            <div className="btn-wrap">
               <div className="btn flex gap-3">
                  <a href="https://t.me/AlchemistDao" target={"_blank"}>
                     <IconBtn
                        //  text={"Join Whatsapp Community"}
                        className="border-1 text-white border-white font-semibold"
                        icon={<FaTelegram className="text-2xl" />}
                     />
                  </a>
                  <a href="https://twitter.com/AlchemistDaO1" target={"_blank"}>
                     <IconBtn
                        //  text={"Join Whatsapp Community"}
                        className="border-1 text-white border-white font-semibold"
                        icon={<FaTwitter className="text-2xl" />}
                     />
                  </a>
               </div>
            </div>
         </div>
      </div>
   );
}
