import "./App.css";
import Waitlist from "./container/Waitlist/Waitlist";
import { Route, Routes, Navigate } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Verify from "./container/Waitlist/Verify";

function App() {
   // window.api = "http://localhost:5000";
   window.api = "https://alchemist-server-ttss.onrender.com";
   return (
      <div className="App custom-scrollbar">
         <ToastContainer
            position="top-right"
            autoClose={1500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
         />

         <Routes>
            <Route path="/" element={<Navigate to="/waitlist" />} />
            <Route path="/waitlist" element={<Waitlist />} />
            <Route path="/waitlist/verify" element={<Verify />} />
         </Routes>
      </div>
   );
}

export default App;
