function obscureEmail(email) {
   // Split the email into local part and domain
   const [localPart, domain] = email.split("@");

   // Obfuscate the local part
   const obscuredLocalPart =
      localPart.substring(0, 3) + "***";

   // Combine the obscured local part and the domain
   const obscuredEmail = `${obscuredLocalPart}@${domain}`;

   return obscuredEmail;
}

module.exports = obscureEmail;
