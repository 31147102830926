import axios from "axios";
import { useContext } from "react";

const authHandler = async (query, data, loading) => {
   try {
      //   loading(true);
      const response = await axios.post(`${window.api}${query}`, data, {
         headers: {
            "Content-Type": "application/json",
         },
      });
      return response.data;
   } catch (error) {
      console.error("Error in authHandler");
      throw error; // You can choose to handle or rethrow the error as needed.
   } finally {
      //   loading(false);
   }
};

export default authHandler;