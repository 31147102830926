import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import getHandler from "../../utils/getHandler";
import getParam from "../../utils/queryGetter";

export default function Verify() {
   useEffect(() => {
      checkToken();
   }, []);

   const navi = useNavigate()

   const token = getParam(window.location.href, "verification-token");

   const checkToken = async () => {
      try {
        if (!token) return alert("Invalid request")
         const response = await toast.promise(
            getHandler("/waitlist/verifyEmail", {
               "verification-token": token,
            }, true),
            {
               pending: "Verifying Email",
               success: "Email verified successfully",
               error: "Unable Verify Email",
            }
         );
         setTimeout(() => {
          navi("/waitlist")
         }, 1500)
      } catch (error) {
         console.log(error);
      }
   };
   return <div>Verify</div>;
}
