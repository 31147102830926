import React from "react";
import { toast } from "react-toastify";
import copyToClipboard from "../../../utils/copyToClipboard";
import IconBtn from "./IconBtn";

export default function RankBox({ data }) {
   const notify = () => {
      toast.success("Copied");
      copyToClipboard(data.inviteLink);
   };
   return (
      <div className="wrap w-full">
         <div className=" bg-neutral-950 rounded-lg p-3 shadow text-center">
            <div className="heading text-2xl font-bold text-cyan-300 mb-2">
               <span>Your Rank</span>
            </div>
            <div className="text-wrap  text-6xl font-extrabold text-white">
               <span>{data.rank}</span>
            </div>
         </div>

         {/* Referral Link Section */}
         <div className="flex justify-between gap-2 mt-4">
            <input
               type="text"
               className=" bg-neutral-950 w-[80%] p-3 rounded-lg text-center text-white "
               value={data.inviteLink}
               disabled
            />{" "}
            <IconBtn
               text={"Copy Link"}
               onClick={notify}
               className="bg-cyan-500"
            />
         </div>
         <div className="text-wrap text-sm text-cyan-500">
            <span>Total Referral: {data.invites}</span>
         </div>

         <div className="text-wrap mt-3 text-gray-300 text-sm">
            <span>
               You get <span className="text-cyan-500">+25</span> for every
               friends you invite. Share your referral link to friends and
               family
            </span>
         </div>
      </div>
   );
}
