import React from "react";

export default function Blockage({text}) {
   return (
      <div className="absolute top-0 bottom-0 z-[100] backdrop-blur-xl -right-0 left-0 flex items-center justify-center">
         <div className="wrap text-2xl text-white rounded-2xl text-center p-4">
         {text}
         </div>
      </div>
   );
}
