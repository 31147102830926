import axios from "axios";
import { useContext } from "react";
import { getCookie, setCookie } from "./cookies";

const getToken = () => {
   const token = getCookie("user-token");

   if (!token) {
      console.error("Error retrieving token:");
      return null;
   }
   return token;
};

const postHandler = async (query, data, customHeaders = {}) => {
   try {
      //   loading(true);
      const response = await axios.post(`${window.api}${query}`, data, {
         headers: {
            "Content-Type": "application/json",
            "user-token": getToken(),
            ...customHeaders,
         },
      });

      return response.data;
   } catch (error) {
      console.error("Error in postHandler");
      throw error; // You can choose to handle or rethrow the error as needed.
   } finally {
      //   loading(false);
   }
};

export default postHandler;
