import React from 'react'
import Leaderboard from './components/Leaderboard'
import PointView from './components/PointView'
import Popup from './components/Popup'
import WaitlistFooter from './components/WaitlistFooter'
import WaitlistHeader from './components/WaitlistHeader'
import WaitlistIntro from './components/WaitlistIntro'

export default function Waitlist() {
  return (
    <div>
        <WaitlistHeader />
        <WaitlistIntro />
        <PointView />
        <Leaderboard />
        <WaitlistFooter />
    </div>
  )
}
