import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import getHandler from "../../../utils/getHandler";
import obscureEmail from "../../../utils/obscureEmail";

export default function Leaderboard() {
   useEffect(() => {
      getLeaderboard();
   }, []);

   const [userStats, setUserStats] = useState([]);

   const getLeaderboard = async () => {
      try {
         const response = await getHandler("/waitlist/leaderboard");
         setUserStats(response.message);
      } catch (error) {
         console.log(error);
      }
   };
   return (
      <div className="py-16">
         <div className="container bg-neutral-950 py-16 lg:px-10 ">
            <div className="heading text-center text-2xl flex text-gray-300 font-bold mb-4">
               <span className="border-cyan-500 border-bottom border-2 p-2 rounded-xl">
                  Leaderboard
               </span>
            </div>

            <div className="table-responsive">
               <table className="w-full">
                  <thead>
                     <tr>
                        <th className="px-3 py-2 bg-gray-300 border-1">S/N</th>
                        <th className="px-3 py-2 bg-gray-300 border-1">User</th>
                        <th className="px-3 py-2 bg-gray-300 border-1">
                           Total Invites
                        </th>
                        <th className="px-3 py-2 bg-gray-300 border-1">Rank</th>
                     </tr>
                  </thead>
                  <tbody>
                     {userStats.map((user, index) => (
                        <tr key={index}>
                           <td className="px-3 bg-neutral-800 py-3 text-white">
                              {index+1}
                           </td>
                           <td className="px-3 bg-neutral-800 py-3 text-white">
                              {obscureEmail(user.user)}
                           </td>
                           <td className="px-3 bg-neutral-800 py-3 text-white">
                              {user.totalInvites}
                           </td>
                           <td className="px-3 bg-neutral-800 py-3 text-white">
                           {user.rank}

                           </td>
                        </tr>
                     ))}
                  </tbody>
               </table>
            </div>
         </div>
      </div>
   );
}
