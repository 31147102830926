import React, { useState } from "react";
import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import postHandler from "../../../utils/postHandler";
import IconBtn from "./IconBtn";

export default function TaskBox({
   socialIcon,
   text,
   isCompleted,
   taskName,
   link,
   refresh,
}) {
   const [click, setClick] = useState(false);

   const completeTask = async () => {
      if (!click) return toast.error("Please complete task before proceeding");
      try {
         const data = { taskName };
         const response = await toast.promise(
            postHandler("/waitlist/task", data),
            {
               pending: "Completeting Task",
               success: "Task Completeted",
               error: "Task not Completeted",
            }
         );

         refresh();
      } catch (error) {
         console.log(error);
      }
   };
   return (
      <div className="grid gap-3 grid-cols-4 p-3 bg-neutral-950 rounded-lg text-white mb-3">
         <div className="flex gap-3 items-center col-span-2">
            <div className="icon-wrap">
               <span>{socialIcon}</span> <br />
            </div>
            <div className="text-wrap text-lg font-semibold">
               <span>{text}</span> <br />
               <span className="text-sm font-thin text-cyan-600">+10 rank</span>
            </div>
         </div>
         <div className="flex justify-cente items-center icon-wrap">
            <a href={link}>
               <IconBtn
                  icon={<FaExternalLinkSquareAlt className="text-lg" />}
                  className={"bg-cyan-600"}
                  onClick={() => setClick(true)}
               />
            </a>
         </div>
         <div className="flex justify-center items-center btn-wrap">
            {isCompleted ? (
               <IconBtn className={"bg-cyan-600 opacity-40"} text="Completed" />
            ) : (
               <IconBtn
                  className={"bg-cyan-600"}
                  text="Complete"
                  onClick={() => completeTask()}
               />
            )}
         </div>
      </div>
   );
}
