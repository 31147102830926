import React from "react";

export default function WaitlistFooter() {
   return (
      <div className="wrap ">
         <div className="container border-t border-cyan-100 border-opacity-20">
            <div className="flex py-4 items-center justify-between gap-4 text-gray-300">
               <span>
                  Powered by{" "}
                  <span className="font-bold text-lg text-white">ALCHEMIST DAO</span>
               </span>
               <span className="text-sm text-yellow-600">All right reserveed. © 2022</span>
            </div>
         </div>
      </div>
   );
}
