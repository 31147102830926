import React from "react";
import { FaCheckDouble } from "react-icons/fa";

export default function Popup({ sent }) {
   return (
      <div
         className="wrap fixed top-0 bottom-0 right-0 left-0 flex justify-center backdrop-blur-lg items-center z-3 p-4"
         onClick={() => sent(false)}
      >
         <div className="col-lg-8 bg-neutral-800 text-white px-4 py-14 rounded-2xl shadow-lg text-center">
            <div className="con">
               <div className="icon-wrap flex justify-center text-8xl mb-10">
                  <FaCheckDouble />
               </div>
               <div className="text-wrap my-4 col-md-6 mx-auto">
                  <span>
                     Please check your email for a verification message. Click
                     the link in the email to verify your email address. Thank
                     you.
                  </span>
               </div>
               <div className="btn-wrap ">
                  <button
                     className="p-3 bg-cyan-600 text-black font-bold rounded-pill"
                     onClick={() => sent(false)}
                  >
                     OK
                  </button>
               </div>
            </div>
         </div>
      </div>
   );
}
