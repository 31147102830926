import axios from "axios";
import { useContext } from "react";
import { getCookie, setCookie } from "./cookies";

const getToken = () => {
   const token = getCookie("user-token");
   if (!token) {
      console.error("Error retrieving token:");
      return null;
   }
   return token;
};

const getHandler = async (query, customHeaders = {}, isVerify, loading) => {
   try {
      //   loading(true);
      const response = await axios.get(`${window.api}${query}`, {
         headers: {
            "Content-Type": "application/json",
            "user-token": getToken(),
            ...customHeaders,
         },
      });

      // for verify email component alone
      if (isVerify) {
         const userToken = response.headers["user-token"];
         setCookie("user-token", userToken);
      }

      return response.data;
   } catch (error) {
      console.error("Error in getHandler");
      throw error; // You can choose to handle or rethrow the error as needed.
   } finally {
      //   loading(false);
   }
};

export default getHandler;
