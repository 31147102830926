import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import IconBtn from "./IconBtn";
import { TypeAnimation } from "react-type-animation";
import Popup from "./Popup";
import authHandler from "../../../utils/authHandler";
import { toast } from "react-toastify";
import getParam from "../../../utils/queryGetter";
import Blockage from "./Blockage";
import { getCookie } from "../../../utils/cookies";

export default function WaitlistIntro() {
   const [isSent, setIsSent] = useState(false);
   const [email, setEmail] = useState("");
   const [loading, setLoading] = useState(false);

   const userToken = getCookie("user-token");

   const handleSubmit = async (e) => {
      e.preventDefault();
      const referral = getParam(window.location.href, "invite");

      try {
         const data = { email, referral: referral ? referral : "system" };

         setLoading(true);

         const response = await toast.promise(
            authHandler("/waitlist/register", data),
            {
               pending: "loading...",
               success: "Request sent Successfully",
               error: "Unkown Error Occured",
            }
         );

         setIsSent(true);

      } catch (error) {
         console.log(error);
      } finally {
         setLoading(false);
      }
   };
   return (
      <div className="wrap bg-con-intro relative">
         {loading && <Blockage />}
         {isSent && <Popup sent={setIsSent} />}
         <div className=" backdrop-blur-xl">
            <div className="container">
               <div className="grid gap-5 lg:grid-cols-2 grid-cols-1 min-h-[80vh]">
                  <div className="flex items-center">
                     <div className="wrap w-full py-32">
                        <div className="text-wrap text-5xl lg:text-7xl  text-yellow-500 font-bold">
                           Revolusionalize the{" "}
                           <TypeAnimation
                              sequence={[
                                 "Future",
                                 2000,
                                 "Finance",
                                 2000,
                                 "Blockchain",
                                 2000,
                              ]}
                              wrapper="span"
                              speed={50}
                              className="text-gray-300 text-5xl lg:text-7xl"
                              repeat={Infinity}
                           />
                        </div>
                        <div className="text-wrap text-gray-200 text-lg mt-3">
                           <span>
                              Connect, Build, Evolve with ALCHEMIST DAO
                           </span>
                        </div>

                        <div className="btn-wrap mt-10">
                           <form onSubmit={handleSubmit}>
                              <div className="flex gap-3">
                                 {!userToken && (
                                    <input
                                       type="email"
                                       name="email"
                                       id="email"
                                       autoComplete="email"
                                       placeholder="example@email.com"
                                       className=" bg-neutral-700  text-gray-200 border-none w-[60%] rounded-lg px-4 py-3"
                                       value={email}
                                       onChange={(e) =>
                                          setEmail(e.target.value)
                                       }
                                       required
                                    />
                                 )}
                                 {userToken ? (
                                    <div className="wrap bg-yellow-500 rounded p-2 capitalize text-md text-black ">
                                       You have joined the whitelist
                                       successfully{" "}
                                    </div>
                                 ) : (
                                    <IconBtn
                                       icon={
                                          <FaArrowRight className="text-sm" />
                                       }
                                       text={"Join Waitlist"}
                                       className="bg-yellow-500 px-3"
                                    />
                                 )}
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>
                  <div className="flex justify-center lg:justify-end items-center">
                     <div className="img-wrap">
                        <img src="/assets/waitlist-intro.png" alt="Home" />
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}
