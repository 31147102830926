import React, { useEffect, useState } from "react";
import { FaTelegram, FaTwitter, FaYoutube } from "react-icons/fa";
import { getCookie, removeCookie } from "../../../utils/cookies";
import getHandler from "../../../utils/getHandler";
import { logout } from "../../../utils/Logout";
import Blockage from "./Blockage";
import RankBox from "./RankBox";
import TaskBox from "./TaskBox";

export default function PointView() {
   const [loggedIn, setLoggedIn] = useState(false);
   useEffect(() => {
      getStats();

      const isLoggedIn = getCookie("user-token");
      if (isLoggedIn) return setLoggedIn(true);
   }, []);
   const [rankBoxData, setRankBoxData] = useState({});
   const [task, setTask] = useState({});
   const getStats = async () => {
      try {
         const response = await getHandler("/waitlist/user-stats");

         const stats = response.message;
         setRankBoxData(stats);
         setTask(stats.task);
      } catch (error) {
         logout();
         console.log(error);
      }
   };
   return (
      <div className="warp py-32 relative">
         {!loggedIn && (
            <Blockage
               text={
                  "Join the waitlist to access profiles, accomplish tasks, and refer friends."
               }
            />
         )}
         <div className="container">
            <div className="grid gap-3 lg:grid-cols-2">
               {/* Rank View */}
               <div className="flex items-center ">
                  <RankBox data={rankBoxData} />
               </div>

               {/* Task Section */}
               <div className="task py-16 lg:py-0">
                  <div className="heading text-xl font-semibold mb-3 text-white">
                     <span>Tasks</span>
                  </div>
                  <TaskBox
                     socialIcon={<FaTwitter />}
                     text="Follow our official Twitter account"
                     taskName={"twitter"}
                     isCompleted={task.twitter}
                     refresh={getStats}
                     link={"https://twitter.com/AlchemistDaO1"}
                  />{" "}
                  <br />
                  <TaskBox
                     socialIcon={<FaTelegram />}
                     text="Join our Telegram community"
                     taskName={"telegram"}
                     isCompleted={task.telegram}
                     refresh={getStats}
                     link={"https://t.me/AlchemistDao"}
                  />
                  <TaskBox
                     socialIcon={<FaYoutube />}
                     text="Subscribe to our youtube channel"
                     taskName={"youtube"}
                     isCompleted={task.youtube}
                     refresh={getStats}
                     link={
                        "https://youtube.com/@AlchemistDao1?si=qHjvq1USYaIdTYqA"
                     }
                  />
               </div>
            </div>
         </div>
      </div>
   );
}
